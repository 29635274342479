import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';

import imgDots from '../assets/images/life-in-barcelona/Dots.svg';
import imgPhoto1 from '../assets/images/life-in-barcelona/sevillie/third.jpg';
import imgPhoto2 from '../assets/images/life-in-barcelona/sevillie/second.jpg';
import imgPhoto3 from '../assets/images/life-in-barcelona/sevillie/fifth.jpg';
import imgReview from '../assets/images/life-in-barcelona/sevillie/first.jpg';

import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/life-in-barcelona-page.scss';

const LifeInBarcelona = () => {
  return (
    <Layout title={{ id: 'sevillie.seo.title' }} description={{ id: 'sevillie.seo.description' }}>
      <div className="life-in-barcelona-page life-in-barcelona-page--sevillie">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="sevillie.sec1.title" />
            </h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-md-first order-last">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    <FormattedMessage id="sevillie.sec2.title" />
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    {/* A seductive city that’s easy to fall head-over-heels in love with, Seville (or
                    Sevilla to{' '}
                    <Link className="red-link" to="/tefl-spanish-course-barcelona/">
                      Spanish speakers
                    </Link>
                    ) mixes big city energy with small town charm. Andaluscía’s sun-soaked capital
                    is an intoxicating blend of vibrant plazas, sublime architecture, centuries-old
                    traditions and captivating culture. */}
                    <FormattedMessage
                      id="sevillie.sec2.description"
                      // values={{
                      //   accentedLink: (chunk) => (
                      //     <FormattedMessage id="url.tefl-spanish-course-barcelona">
                      //       {(locale) => (
                      //         <Link className="red-link" to={locale[0]}>
                      //           {chunk}
                      //         </Link>
                      //       )}
                      //     </FormattedMessage>
                      //   ),
                      // }}
                    />
                  </p>
                  <p className="c-text-16 block__description">
                    <FormattedMessage
                      id="sevillie.sec2.text1"
                      // values={{
                      //   i: (chunk) => <i>{chunk}</i>,
                      //   i2: (chunk) => <i>{chunk}</i>,
                      // }}
                    />
                    <br />
                    <br />
                    <FormattedMessage
                      id="sevillie.sec2.text2"
                      // values={{
                      //   accentedLink: (chunk) => (
                      //     <FormattedMessage id="url.guide-to-living-and-teaching-english-in-barcelona">
                      //       {(locale) => (
                      //         <Link className="red-link" to={locale[0]}>
                      //           {chunk}
                      //         </Link>
                      //       )}
                      //     </FormattedMessage>
                      //   ),
                      // }}
                    />
                  </p>
                  <a
                    href={fileTEFLIberiaBrochure}
                    className="c-btn c-btn--red"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="homepage.sec6.downloadBrochure" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-md-last order-first">
                <div className="block_r">
                  <div className="block_r__img">
                    <img loading="lazy" src={imgPhoto1} alt="" className="block_r__img" />
                  </div>

                  <div className=" block_r__trinity">
                    <img loading="lazy" src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section">
          <div className="container">
            <div className="wrapper">
              <div className="row top">
                <div className="col-md-6">
                  <div className="item">
                    <h2 className="item__title">
                      <FormattedMessage id="sevillie.sec3.titleL" />
                    </h2>
                    <p className="item__description">
                      <FormattedMessage id="sevillie.sec3.textL1" />
                      <br />
                      <br />
                      <FormattedMessage id="sevillie.sec3.textL2" />
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <h2 className="item__title">
                      <FormattedMessage id="sevillie.sec3.titleR" />
                    </h2>
                    <p className="item__description">
                      <FormattedMessage id="sevillie.sec3.textR1" />
                      <br />
                      <br />
                      <FormattedMessage
                        id="sevillie.sec3.textR2"
                        values={{
                          accentedLink: (chunk) => (
                            <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.index">
                              {(locale) => (
                                <Link className="red-link" to={locale[0]}>
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="wrapper-bottom">
                <div className="row cost-of-living">
                  <div className="col-md-6">
                    <div className="block_l">
                      <h2 className="c-title-32 block_l__title">
                        <FormattedMessage id="sevillie.sec3.title" />
                      </h2>
                      <p className="c-text-18 block_l__description">
                        <FormattedMessage id="sevillie.sec3.description" />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="block_r">
                      <p className="block_r__description c-text-18">
                        <FormattedMessage id="sevillie.sec3.fact" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row options">
                  <div className="table-content">
                    <div className="line line--top">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.item" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="sevillie.sec3.sevillie" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="sevillie.sec3.london" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="sevillie.sec3.newYork" />
                      </p>
                      <p className="second">
                        <FormattedMessage id="sevillie.sec3.austin" />
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.bedroomApartment" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.sevillie" />:
                        </span>{' '}
                        330€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          {' '}
                          <FormattedMessage id="sevillie.sec3.london" />:
                        </span>{' '}
                        1263€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.newYork" />:
                        </span>{' '}
                        2154€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.austin" />:
                        </span>{' '}
                        1460€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.mealInExpensive" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.sevillie" />:
                        </span>{' '}
                        10€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.london" />:
                        </span>{' '}
                        17€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.newYork" />:
                        </span>{' '}
                        23.55€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.austin" />:
                        </span>{' '}
                        16.96€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.cappuccino" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.sevillie" />:
                        </span>{' '}
                        1.58€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.london" />:
                        </span>{' '}
                        3.82€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.newYork" />:
                        </span>{' '}
                        4.89€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.austin" />:
                        </span>{' '}
                        4.76€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.beer" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.sevillie" />:
                        </span>{' '}
                        2.50€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.london" />:
                        </span>{' '}
                        6.82€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.newYork" />:
                        </span>{' '}
                        7.54€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.austin" />:
                        </span>{' '}
                        5.65€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.metroTicket" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.sevillie" />:
                        </span>{' '}
                        1.40€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.london" />:
                        </span>{' '}
                        2.96€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.newYork" />:
                        </span>{' '}
                        2.59€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.austin" />:
                        </span>{' '}
                        1.40€
                      </p>
                    </div>
                    <div className="line">
                      <p className="first">
                        <FormattedMessage id="sevillie.sec3.kmInTaxi" />
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.sevillie" />:
                        </span>{' '}
                        0.97€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.london" />:
                        </span>{' '}
                        1.93€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.newYork" />:
                        </span>{' '}
                        1.75€
                      </p>
                      <p className="second">
                        <span className="hidden">
                          <FormattedMessage id="sevillie.sec3.austin" />:
                        </span>{' '}
                        1.46€
                      </p>
                    </div>
                  </div>
                </div>
                <a href="http://www.numbeo.com/" className="grey-link">
                  <FormattedMessage id="sevillie.sec3.source" />:{' '}
                  <span className="underline">http://www.numbeo.com/</span>
                </a>
                {/* <p className="notice">
                  As you can see, Seville is a lot cheaper than its international counterparts!
                  And this is another factor that makes the city a great place to live.
                </p> */}
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="sevillie.sec4.title" />
                  </h2>

                  <p className="block_l__text">
                    <FormattedMessage
                      id="sevillie.sec4.text1"
                      // values={{
                      //   accentedLink: (chunk) => (
                      //     <FormattedMessage id="url.working-as-a-teacher.student-visas">
                      //       {(locale) => (
                      //         <Link className="red-link" to={locale[0]}>
                      //           {chunk}
                      //         </Link>
                      //       )}
                      //     </FormattedMessage>
                      //   ),
                      //   accentedLink2: (chunk) => (
                      //     <GatsbyLink
                      //       className="red-link"
                      //       to="/blog/do-i-need-a-spanish-bank-account/"
                      //     >
                      //       {chunk}
                      //     </GatsbyLink>
                      //   ),
                      // }}
                    />
                    <br />
                    <br />
                    <FormattedMessage id="sevillie.sec4.text2" />
                    <br />
                    <br />
                    <FormattedMessage id="sevillie.sec4.text3" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img loading="lazy" src={imgPhoto2} alt="imgPhoto" className="block_r__img" />
                  <img loading="lazy" src={imgPhoto3} alt="imgDots" className="secondary-img" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fifth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="block_l">
                  <h2 className="c-title-34 block_l__title">
                    {' '}
                    <FormattedMessage id="sevillie.sec5.title" />
                  </h2>
                  <div className="block_l__img">
                    <img loading="lazy" src={imgReview} alt="imgPhoto4" />
                  </div>
                  <p className="img-comment">
                    <FormattedMessage id="sevillie.sec5.imgDescription" />
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block_r">
                  <h3 className="block_r__title">
                    <FormattedMessage id="sevillie.sec5.thing1.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="sevillie.sec5.thing1.text" />
                    <br />
                    <br />
                    <FormattedMessage
                      id="sevillie.sec5.thing1.text2"
                      values={{
                        accentedLink: (chunk) => (
                          <a
                            target="_blank"
                            href="https://www.casadelamemoria.es/en/"
                            className="red-link"
                          >
                            {chunk}
                          </a>
                        ),
                        accentedLink2: (chunk) => (
                          <a
                            target="_blank"
                            href="https://lacasadelflamencosevilla.com/en/"
                            className="red-link"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                    <h4>
                      {' '}
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <a
                        target="_blank"
                        href="https://notjustatourist.com/the-12-best-places-to-watch-flamenco-seville/"
                        className="red-link"
                      >
                        <FormattedMessage id="sevillie.sec5.thing1.link1" />
                      </a>{' '}
                    </p>
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="sevillie.sec5.thing2.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="sevillie.sec5.thing2.text" />
                    <br />
                    <br />
                    <FormattedMessage id="sevillie.sec5.thing2.text2" />
                    <h4>
                      {' '}
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <a
                        target="_blank"
                        href="https://notjustatourist.com/the-8-best-museums-in-seville/"
                        className="red-link"
                      >
                        <FormattedMessage id="sevillie.sec5.thing2.link1" />
                      </a>{' '}
                    </p>
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="sevillie.sec5.thing3.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="sevillie.sec5.thing3.text" />
                    <br />
                    <br />
                    <FormattedMessage id="sevillie.sec5.thing3.text2" />
                    <h4>
                      {' '}
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.ibericoclub.com/the-10-best-tapas-bars-in-seville/#gref"
                        className="red-link"
                      >
                        <FormattedMessage id="sevillie.sec5.thing3.link1" />
                      </a>{' '}
                    </p>
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="sevillie.sec5.thing4.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage
                      id="sevillie.sec5.thing4.text"
                      values={{
                        accentedLink: (chunk) => (
                          <a
                            target="_blank"
                            href="https://sevillecityguide.com/plaza-de-espana-seville.html"
                            className="red-link"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                    <h4>
                      {' '}
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.visitasevilla.es/en/history/sevilla-through-its-parks"
                        className="red-link"
                      >
                        <FormattedMessage id="sevillie.sec5.thing4.link1" />
                      </a>{' '}
                    </p>
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="sevillie.sec5.thing5.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="sevillie.sec5.thing5.text" />
                    <br />
                    <br />
                    <FormattedMessage id="sevillie.sec5.thing5.text2" />
                    <h4>
                      {' '}
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <a
                        target="_blank"
                        href="https://sevillecityguide.com/events-seville.html"
                        className="red-link"
                      >
                        <FormattedMessage id="sevillie.sec5.thing5.link1" />
                      </a>
                    </p>
                  </p>
                  <h3 className="block_r__title">
                    <FormattedMessage id="sevillie.sec5.thing6.title" />
                  </h3>
                  <p className="block_r__description">
                    <FormattedMessage id="sevillie.sec5.thing6.text" />
                    <h4>
                      {' '}
                      <FormattedMessage id="barcelona.sec5.readMore" />
                    </h4>
                    <p>
                      <a
                        target="_blank"
                        href="https://welcometoseville.com/flea-markets-in-seville/"
                        className="red-link"
                      >
                        <FormattedMessage id="sevillie.sec5.thing6.link1" />
                      </a>
                    </p>
                    <br />
                    <p>
                      <FormattedMessage
                        id="sevillie.sec5.moreInfo"
                        values={{
                          strong: (chunk) => <strong>{chunk}</strong>,
                          accentedLink: (chunk) => (
                            <FormattedMessage id="url.blog">
                              {(locale) => (
                                <Link to={locale[0]} className="red-link">
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default LifeInBarcelona;
